<template>
  <section class="verification-code">
    <h1 class="title">{{ langVerificationCode.title }}</h1>
    <p class="desc">
      {{ langVerificationCode.desc }}{{ " " }}&lrm;{{
        this.apply === "logOff"
          ? this.$commonFunc.removeSensitiveData(account)
          : account
      }}
    </p>
    <div class="inp-box">
      <input-verification
        :account="account"
        :from="from"
        :resendType="resendType"
      />
    </div>
    <div class="count-down" :class="{ gray: !resendFlag }" @click="resendFn">
      {{ langVerificationCode.resend
      }}<span v-show="!resendFlag">({{ countDownTime }}s)</span>
    </div>
    <p class="tips" @click="noCode">{{ langVerificationCode.tips }}</p>
  </section>
</template>

<script>
import { mapState } from "vuex";
// import { langVerificationCode } from "./language";
import InputVerification from "./components/input-verification.vue";
import { alexaSendCode, googleSendVerifyCode } from "@/api/alexaLogin.js";
export default {
  data() {
    return {
      resendFlag: false,
      countDownTime: 60,
      timerId: null,
      account:
        this.$commonFunc.getUrlParams()?.apply === "logOff"
          ? this.$commonFunc.decrypt(this.$route.query.account)
          : this.$route.query.account,
      from: this.$route.query.from,
      apply: this.$commonFunc.getUrlParams()?.apply,
    };
  },
  computed: {
    ...mapState("alexaLogin", ["tabIndex", "languagePackage"]),
    langVerificationCode() {
      return this.languagePackage[this.tabIndex];
    },
    resendType() {
      // 验证码类型 1. 注册 2. 重新设置
      return {
        register: 1,
        reset: 2,
      }[this.from];
    },
  },
  components: { InputVerification },
  mounted() {
    this.countDown();
  },
  beforeUnmount() {
    clearInterval(this.timerId);
  },
  methods: {
    noCode() {
      this.$router.push(
        `/${this.$commonFunc.getUrlChannelAppID()}/no-code-received${this.$commonFunc.linkUrlParams()}`
      );
    },
    // 开启倒计时
    countDown() {
      const beginTime = +window.sessionStorage.getItem("countDownTimeStamp");
      const dateStamp = new Date().valueOf();
      if (isNaN(beginTime) || beginTime === 0) {
        window.sessionStorage.setItem("countDownTimeStamp", dateStamp);
        this.countDownTime = 60;
      } else if ((dateStamp - beginTime) / 1000 > 60) {
        //大于60
        window.sessionStorage.removeItem("countDownTimeStamp");
        this.resendFlag = true;
      } else {
        this.countDownTime = Math.floor(60 - (dateStamp - beginTime) / 1000);
      }
      this.timerId = setInterval(() => {
        if (this.countDownTime === 1) {
          this.resendFlag = true;
          clearInterval(this.timerId);
          window.sessionStorage.removeItem("countDownTimeStamp");
        } else {
          this.countDownTime--;
        }
      }, 1000);
    },
    // 重新发送
    resendFn() {
      if (!this.resendFlag) return;
      this.resendFlag = false;
      this.countDown();
      const userFlag = this.account.indexOf("@") != "-1" ? 0 : 1; //0：邮箱、1：手机，
      if (this.apply === "logOff") {
        const loginKey = window.sessionStorage.getItem("loginKey");
        googleSendVerifyCode({
          verifyIdReceiver: this.account,
          type: 5,
          appCode: this.$commonFunc.getUrlAppId(),
          language: this.$commonFunc.getApiLanguage(this.tabIndex),
          loginKey,
        })
          .then((res) => {
            console.log(res, "res");
          })
          .catch((err) => {
            console.log(err, "err");
            this.$toast(err?.data?.msg);
          });
        return;
      }
      alexaSendCode({
        verifyIdReceiver: this.account,
        type: this.resendType,
        userFlag,
        language: this.$commonFunc.getApiLanguage(this.tabIndex),
      });
    },
  },
};
</script>

<style lang="less">
.verification-code {
  font-size: 14px;
  padding: 0 30px;
  padding-top: 40px;
  .title {
    font-size: 22px;
    color: rgba(0, 0, 0, 0.9);
  }
  .desc {
    margin-top: 12px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
  }
  .inp-box {
    margin: 37px 0 33px 0;
  }
  .count-down {
    cursor: pointer;
    color: #267aff;
    &.gray {
      color: rgba(38, 122, 255, 0.4);
    }
  }
  .tips {
    margin-top: 133px;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    cursor: pointer;
  }
}
</style>
