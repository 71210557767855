<template>
  <div class="input-verification">
    <div class="captcha">
      <input
        v-for="(c, index) in ct"
        :key="index"
        type="number"
        pattern="^[0-9]{1}$"
        v-model="ct[index]"
        ref="input"
        :style="{ borderBottomColor: index <= cIndex ? '#333' : '' }"
        @input="
          (e) => {
            onInput(e.target.value, index);
          }
        "
        @keydown.delete="
          (e) => {
            onKeydown(e.target.value, index);
          }
        "
        @focus="onFocus"
        :disabled="loading"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import { langVerificationCode } from "../language";
import {
  alexaVerifyId,
  googleCancel,
  googleGetSecretKey,
} from "@/api/alexaLogin.js";
export default {
  data() {
    return {
      ct: ["", "", "", "", "", ""],
      loading: false,
      msg: "",
      verifyIdReceiver: "",
      type: 1,
      boolVerify: false, //优化多次触发验证
      apply: this.$commonFunc.getUrlParams()?.apply,
      appId: this.$commonFunc.getUrlAppId(),
    };
  },
  props: {
    account: {
      type: String,
      default: "",
    },
    from: {
      type: String,
      default: "",
    },
    resendType: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapState("alexaLogin", ["tabIndex", "languagePackage"]),
    langVerificationCode() {
      return this.languagePackage[this.tabIndex];
    },
    ctSize() {
      return this.ct.length;
    },
    cIndex() {
      let i = this.ct.findIndex((item) => item === "");
      i = (i + this.ctSize) % this.ctSize;
      return i;
    },
    lastCode() {
      return this.ct[this.ctSize - 1];
    },
  },
  watch: {
    cIndex() {
      this.resetCaret();
    },
    lastCode(val) {
      if (val) {
        console.log("this.ctSize", this.ctSize);
        this.$refs.input[this.ctSize - 1].blur();
        this.sendCaptcha();
      }
    },
    account(val) {
      this.verifyIdReceiver = val;
    },
    resendType(val) {
      this.type = val;
    },
  },
  mounted() {
    this.resetCaret();
  },
  methods: {
    onInput(val, index) {
      // 控制只能输入数字
      if (!/^[0-9]*$/.test(val) || /^\s*$/.test(val)) {
        return (this.ct[index] = "");
      }
      this.msg = "";
      val = val.replace(/\s/g, "");
      if (index == this.ctSize - 1) {
        this.ct[this.ctSize - 1] = val[0]; // 最后一个码，只允许输入一个字符。
      } else if (val.length > 1) {
        let i = index;
        for (i = index; i < this.ctSize && i - index < val.length; i++) {
          this.ct[i] = val[i];
        }
        this.resetCaret();
      }
    },
    // 重置光标位置。
    resetCaret() {
      this.$refs.input[this.ctSize - 1].focus();
    },
    onFocus() {
      // 监听 focus 事件，将光标重定位到“第一个空白符的位置”。
      let index = this.ct.findIndex((item) => item === "");
      index = (index + this.ctSize) % this.ctSize;
      this.$refs.input[index].focus();
    },
    onKeydown(val, index) {
      if (val === "") {
        // 删除上一个input里的值，并对其focus。
        if (index > 0) {
          this.ct[index - 1] = "";
          this.$refs.input[index - 1].focus();
        }
      }
    },
    sendCaptcha() {
      if (this.boolVerify) return;
      this.boolVerify = true;
      this.msg = `发送验证码到服务器：${this.ct.join("")}`;
      console.log(this.msg, this.langVerificationCode);
      // 此时无法操作 input。。
      this.loading = true;
      if (this.apply === "logOff") {
        googleGetSecretKey({ appCode: this.appId }).then((res) => {
          const loginKey = window.sessionStorage.getItem("loginKey");
          googleCancel({
            verifyIdReceiver: this.account,
            // type: 5,
            account: this.account,
            verifyCode: this.$commonFunc.encryptCodeSecret(
              this.ct.join(""),
              res.secretKey
            ),
            appCode: this.appId,
            language: this.$commonFunc.getApiLanguage(this.tabIndex),
            loginKey,
          })
            .then((res) => {
              console.log(res, "res");
              this.boolVerify = false;
              this.$router.replace(
                `/${this.$commonFunc.getUrlChannelAppID()}/cancel-success`
              );
            })
            .catch((err) => {
              console.log(err, "err");
              this.boolVerify = false;
              this.$toast(err?.data.msg || this.langVerificationCode.codeErr);
              this.loading = false;
            });
        });
        return;
      }
      alexaVerifyId({
        verifyIdReceiver: this.account,
        type: this.type,
        verifyId: this.$commonFunc.encryptCode(this.ct.join("")),
      })
        .then((res) => {
          this.boolVerify = false;
          this.$router.replace(
            `/${this.$commonFunc.getUrlChannelAppID()}/set-password${this.$commonFunc.linkUrlParams(
              {
                initParams: {
                  verifyId: res?.verifyId,
                  from: this.from,
                  account: this.account,
                },
              }
            )}`
          );
        })
        .catch(() => {
          this.boolVerify = false;
          this.$toast(this.langVerificationCode.codeErr);
          this.loading = false;
          // this.$nextTick(() => {
          //   this.reset();
          // });
        });
    },
    reset() {
      // 重置。一般是验证码错误时触发。
      this.ct = this.ct.map(() => "");
      this.resetCaret();
    },
  },
};
</script>

<style lang="less" scoped>
.captcha {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
input {
  width: 13%;
  text-align: center;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #eee;
  font-size: 24px;
  outline: none;
}
input:last-of-type {
  margin-right: 0;
}
input:disabled {
  color: #000;
  background-color: #fff;
}
.msg {
  text-align: center;
}
input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield; /* 兼容 Firefox */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
